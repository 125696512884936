import React, { useState } from "react";
import cover_video from "Assets/Video/cover_video.mp4";
import canAutoPlay from "can-autoplay";
import sea from "Assets/Images/sea.jpg";
import {
  CoverVideo,
  Sidebar,
  Experience,
  Skills,
  Projects,
  Contact,
  About,
} from "Components/";
import "video.js/dist/video-js.css";
import "./LandingPage.scss";

const LandingPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const videoJsOptions = {
    autoplay: true,
    controls: false,
    loop: true,
    preload: true,
    muted: true,
    poster: sea,
    sources: [
      {
        src: cover_video,
        type: "video/mp4",
      },
    ],
  };

  return (
    <div className="landing-header">
      <Sidebar />
      <div className="landing-section">
        <div className="main-section-text">
          <h1>
            JOSH
            <br />
            GORMAN
          </h1>
        </div>
        <CoverVideo
          style={{
            display: isLoading ? "none" : "block",
            flexBasis: "fill",
          }}
          onPlayerReady={() => {
            canAutoPlay.video({ muted: true }).then(({ result }) => {
              if (result === true) {
                setIsLoading(false);
              }
            });
          }}
          {...videoJsOptions}
        />
        <div className="empty-div"></div>
        <About />
        <Skills />
        <Experience />
        <Projects />
        <Contact />
      </div>
    </div>
  );
};

export default LandingPage;
