import React from "react";
import Container from "@material-ui/core/Container";
import "./About.scss";

const About = () => {
  return (
    <div className="about-container">
      <Container>
        <a name="about">
          <h3 className="section-heading">About Me</h3>
        </a>
        <p className="about-content">
          A Dev-Ops Engineer and Full-stack Developer with extensive experience
          developing applications and managing infrastructure for progressive
          web applications on public clouds. Always looking for new ventures
          where I can apply my expertise, learn, and evolve.
        </p>
      </Container>
    </div>
  );
};

export default About;
