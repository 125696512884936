import { LandingPage } from "pages";
import './App.scss';

/* Global CSS */
import "Styles/fonts.scss";
import "Styles/globals.scss";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <LandingPage />
      </header>
    </div>
  );
}

export default App;
