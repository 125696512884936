import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import "./Experience.scss";

const education = [
  {
    title: "Carleton University",
    year: "2016-2020",
    program: "Honors Computer Science",
    desc: "Honors, Co-op",
  },
];
const work = [
  {
    title: "TD Bank",
    year: "May 2021",
    yearTwo: "- Present",
    program: "Associate Engineer II",
    desc: [
      "Developed and experimented with Node.js and Go backend servers to develop a performant backend server with concurrency to enhance project runtime.",
      "Assisted in cost calculations and development of a from-scratch database solution making use of a serverless architecture to reduce costs for small to mid-sized customers by over 90%.",
      "Created performant, asynchronous, backend connected UI features with Node.js, React.js and MobX while ensuring test support with Jest and Cypress.",
    ],
  },
  {
    title: "Micro Focus",
    year: "May 2020",
    yearTwo: "- August 2020",
    program: "UI/UX Engineer Intern",
    desc: [
      "Developed and experimented with Node.js and Go backend servers to develop a performant backend server with concurrency to enhance project runtime.",
      "Assisted in cost calculations and development of a from-scratch database solution making use of a serverless architecture to reduce costs for small to mid-sized customers by over 90%.",
      "Created performant, asynchronous, backend connected UI features with Node.js, React.js and MobX while ensuring test support with Jest and Cypress.",
    ],
  },
  {
    title: "ISED",
    year: "May 2019",
    yearTwo: "- August 2019",
    program: "Student Full-Stack Developer",
    desc: [
      "Implemented a skill tracking full-stack application from scratch with support from senior staff to increase collaboration between employees.",
      "Developed and ensured test coverage for over 75% of the user interface as well as full-stack features in a multi-service clustered application using React.js, Redux, Redux Sagas, Feathers.js and Sequelize ORM.",
      "Shadowed under a certified GCP Engineer to deploy cloud infrastructure and automated deployments on GCP as well as cloning said deployments to Open stack and AWS.",
    ],
  },
  {
    title: "CFIA",
    year: "January 2018",
    yearTwo: "- August 2018",
    program: "Student Web Developer",
    desc: [
      "Developed SOAP web services using Java and Apache Tomcat to provide dynamic data loadable into PDF forms.",
      "Experimented and documented features usable for the team in the content management system AEM using Java and JSP.",
    ],
  },
  {
    title: "Red-D-Arc",
    year: "July 2015",
    yearTwo: "- August 2015",
    program: "Junior Business Analyst",
    desc: [
      "Worked on multiple projects including testing and validation of ERP software, the preparation and release of localized web services and the reduction of company resources through reduced re-printing.",
    ],
  },
];

const Experience = () => {
  return (
    <div className="experience-container">
      <Container>
        <a name="experience">
          <h2 className="section-heading">Experience</h2>
        </a>

        <h3 className="experience-subheading">Education</h3>
        <div className="experience-cont">
          {education.map((occ) => (
            <div>
              <h4 className="experience-title">{occ.title}</h4>
              <div className="experience-section">
                <div className="experience-div experience-first">
                  <p className="experience-year">{occ.year}</p>
                </div>
                <div className="experience-div experience-second">
                  <h5 className="experience-program">{occ.program}</h5>
                  <p className="experience-content">{occ.desc}</p>
                  <p className="experience-link">{occ.link}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <h3 className="experience-subheading">Work Experience</h3>
        <div className="experience-cont">
          {work.map((occ) => (
            <div>
              <h4 className="experience-title">{occ.title}</h4>
              <div className="experience-section">
                <div className="experience-div experience-first">
                  <p className="experience-year">
                    {occ.year}
                    <br />
                    {occ.yearTwo}
                  </p>
                </div>
                <div className="experience-div experience-second">
                  <h5 className="experience-program">{occ.program}</h5>
                  <ul className="experience-content">
                    {occ.desc.map((item) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                  <p className="experience-link">{occ.link}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Experience;
