import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import azure from "Assets/Images/azure.svg";
import salt from "Assets/Images/saltstack.svg";
import "./Skills.scss";

const Skills = () => {
  return (
    <div className="skills-container">
      <Container>
        <a name="skills">
          <h2 className="section-heading">Skills</h2>
        </a>
        <div className="skills-section">
          <div className="skills-div">
            <h3 className="skills-heading">Dev-Ops</h3>
            <a className="icon" href="https://aws.amazon.com/">
              <i class="devicon-amazonwebservices-original"></i>
            </a>
            <a href="https://azure.microsoft.com/en-us/">
              <img src={azure} alt="azure icon" className="img"></img>
            </a>
            <a href="https://saltproject.io/">
              <img src={salt} alt="saltstack icon" className="img"></img>
            </a>
            <a className="icon" href="https://www.docker.com/">
              <i class="devicon-docker-plain"></i>
            </a>
            <a className="icon" href="https://kubernetes.io/">
              <i class="devicon-kubernetes-plain"></i>
            </a>
            <a className="icon" href="https://www.linux.org/">
              <i class="devicon-linux-plain"></i>
            </a>
            <a className="icon" href="https://www.gnu.org/software/bash/">
              <i class="devicon-bash-plain"></i>
            </a>
            <a className="icon" href="https://www.jenkins.io/">
              <i class="devicon-jenkins-plain"></i>
            </a>
          </div>
          <div className="skills-div">
            <h3 className="skills-heading">Backend</h3>
            <a className="icon" href="https://www.mongodb.com/">
              <i class="devicon-mongodb-plain"></i>
            </a>
            <a className="icon" href="https://nodejs.org/en/">
              <i class="devicon-nodejs-plain"></i>
            </a>
            <a className="icon" href="https://www.postgresql.org/">
              <i class="devicon-postgresql-plain"></i>
            </a>
            <a className="icon" href="https://sequelize.org/">
              <i class="devicon-sequelize-plain"></i>
            </a>
          </div>
          <div className="skills-div">
            <h3 className="skills-heading">General</h3>
            <a className="icon" href="https://www.javascript.com/">
              <i class="devicon-javascript-plain"></i>
            </a>
            <a className="icon" href="https://git-scm.com/">
              <i class="devicon-git-plain"></i>
            </a>
            <a className="icon" href="https://github.com">
              <i class="devicon-github-original"></i>
            </a>
            <a className="icon" href="https://www.python.org/">
              <i class="devicon-python-plain"></i>
            </a>
          </div>
          <div className="skills-div">
            <h3 className="skills-heading">Frontend</h3>
            <a
              className="icon"
              href="https://developer.mozilla.org/en-US/docs/Web/CSS"
            >
              <i class="devicon-css3-plain"></i>
            </a>
            <a className="icon" href="https://www.figma.com/">
              <i class="devicon-figma-plain"></i>
            </a>
            <a className="icon" href="https://www.photoshop.com/en">
              <i class="devicon-photoshop-plain"></i>
            </a>
            <a className="icon" href="https://reactjs.org/">
              <i class="devicon-react-original"></i>
            </a>
            <a className="icon" href="https://sass-lang.com/guide">
              <i class="devicon-sass-original"></i>
            </a>
            <a className="icon" href="https://material-ui.com/">
              <i class="devicon-materialui-plain"></i>
            </a>
            <a className="icon" href="https://jestjs.io/">
              <i class="devicon-jest-plain"></i>
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Skills;
