import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import "./Contact.scss";

const Contact = () => {
  return (
    <div className="contact-container">
      <Container>
        <a name="contact">
          <h3 className="contact-heading">Lets Talk</h3>
        </a>
        <p className="contact-content">
          Connect with me on{" "}
          <a
            className="contact-link"
            href="https://www.linkedin.com/in/josh-gorman/"
          >
            LinkedIn
          </a>{" "}
          or follow me on{" "}
          <a className="contact-link" href="https://github.com/Liannus">
            Github
          </a>
        </p>
        <p className="contact-explanation">© Joshua Gorman Made in React.js</p>
      </Container>
    </div>
  );
};

export default Contact;
