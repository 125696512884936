import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import tbw from "Assets/Images/tbw.png";
import cds from "Assets/Images/cds.png";
import wlk from "Assets/Images/wlk.png";
import "./Projects.scss";

const projects = [
  {
    project: "Thompson Boiler Works",
    year: "2016-2020",
    company: "J&E Design Co.",
    imageLink: tbw,
    imageAlt:
      "Image showing various screenshots from Thompson Boiler Works website",
    desc: "A dynamic website built for Thompson Boiler Works. Containing a backend Node.js server to control API requests, frontend React server to present the UI, and all deployed to Amazon Web Services through Github Actions.",
    link: "https://github.com/JE-Design/Thompson-Boiler-Works",
  },
  {
    project: "Clarity Detailing Solutions",
    year: "2016-2020",
    company: "J&E Design Co.",
    imageLink: cds,
    imageAlt:
      "Image showing various screenshots from Clarity Detailing Solutions website",
    desc: "A static website built for Clarity Detailing Solutions, developed with a custom frontend react server, API driven widgets for dynamic functionality and deployed to Netlify for simple no-fuss deployments.",
    link: "https://github.com/JE-Design/Clarity-Detailing-Solutions",
  },
  {
    project: "Wolkstack",
    year: "2016-2020",
    company: "Carleton University Honours Project",
    imageLink: wlk,
    imageAlt: "Image showing screenshoots from the Wolkstack Honors Project",
    desc: "A dynamic Terraform/Helm application for bootstrapping Kubernetes based web applications with a powerful Python based CLI.",
    link: "https://github.com/Liannus/Wolkstack",
  },
];

const Projects = () => {
  return (
    <div className="projects-container">
      <Container className="projects-material-container">
        <a name="projects">
          <h2 className="section-heading">Projects</h2>
        </a>

        {projects.map((occ) => (
          <div>
            <h3 className="projects-project">{occ.project}</h3>
            <div className="projects-info">
              <p className="projects-year">{occ.year}</p>
              <p>|</p>
              <p className="projects-company">{occ.company}</p>
            </div>
            <img
              className="projects-image"
              alt={occ.imageAlt}
              src={occ.imageLink}
            ></img>
            <p className="projects-content">{occ.desc}</p>
            <a href={occ.link} className="projects-link">
              {occ.link}
            </a>
          </div>
        ))}
      </Container>
    </div>
  );
};

export default Projects;
