import React from "react";
import "./Sidebar.scss";
import { IconButton, Drawer, Hidden } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";
import GetAppIcon from "@material-ui/icons/GetApp";
import resume from "Assets/Josh Gorman Resume.pdf";
import logo from "logo.png";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    size: "medium",
    position: "absolute",
    top: "15px",
    right: "30px",
    zIndex: 3,
    padding: "10px",
    color: "#181a1b",
    backgroundColor: "#ecebe7",
    borderRadius: "30%",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.6)",
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#181a1b",
    mozBoxShadow: "inset -5px 0px 10px -4px #000",
    webkitBoxShadow: "inset -5px 0px 10px -4px #000",
    boxShadow: "inset -5px 0px 10px -4px #000",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const Sidebar = (props) => {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div className="sidebar-content">
      <div className="logo-header">
        <img className="logo-image" src={logo} alt="Josh Gormans Logo" />
        <h2>
          FULL-STACK <br />
          DEVELOPER
        </h2>
      </div>
      <hr className="linebreak" />
      <div className="links">
        <a href="#about">ABOUT</a>
        <a href="#skills">SKILLS</a>
        <a href="#experience">EXPERIENCE</a>
        <a href="#projects">PROJECTS</a>
        <a href="#contact">CONTACT</a>
        <a download href={resume}>
          {`RESUME `}
          <GetAppIcon />
        </a>
      </div>
      <div className="sidebar-footer">
        <div className="sidebar-footer-links">
          <a href="https://www.linkedin.com/in/josh-gorman/">
            <LinkedInIcon />
          </a>
          <a href="https://github.com/Liannus">
            <GitHubIcon />
          </a>
        </div>
        <p>
          <hr />
          © Joshua Gorman <br />
          Made in React.js
        </p>
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <IconButton
        color="red"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        className={classes.menuButton + " drawer-button"}
      >
        <MenuIcon />
      </IconButton>
      <div className="sidebar">
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Hidden mdUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
